/// src/router/index.js

import { createRouter, createWebHashHistory } from "vue-router";
import LoginAIO from "../components/LoginAIO.vue";
import Dashboard from "../components/Dashboard.vue";
import AddSeller from "../components/AddSeller.vue";
import AddGroup from "../components/AddGroup.vue";
import SellersList from "../components/SellersList.vue";
import SellerLogin from "../components/SellerLogin.vue";
import SellerDashboard from "../components/SellerDashboard.vue";
import store from "../store";
import SellerGroupList from "../components/SellerGroupList.vue";
import GroupAdminLogin from "../components/GroupAdminLogin.vue";
import GroupAdminDashboard from "../components/GroupAdminDashboard.vue";
import AdminSellers from "../components/AdminSellers.vue";
import AdminLogin from "../components/AdminLogin.vue";
import AdminDashboard from "../components/AdminDashboard.vue";
import AdminSellerEdit from "../components/AdminSellerEdit.vue";
import TagsList from "../components/TagsList.vue";
import AddTag from "../components/AddTag.vue";
import TagGroupsList from "../components/TagGroupsList.vue";
import PartnersList from "../components/PartnersList.vue";
import AddPartner from "../components/AddPartner.vue";
import ConfigManager from "../components/ConfigManager.vue";
import PlaylistsCreate from "../components/PlaylistsCreate.vue";
import PlaylistsList from "../components/PlaylistsList.vue";
import AdminAdsContent from "../components/AdminAdsContent.vue";
import AdminAdsTxtManager from "@/components/AdminAdsTxtManager.vue";
import DeveloperApi from "../components/DeveloperApi/DeveloperApi.vue";
import DeveloperApiDoc from "../components/DeveloperApiDoc/DeveloperApiDoc.vue";
import TagsListChecker from "../components/TagsListChecker.vue";
import PartnerTagsList from "../components/PartnerTagsList.vue";
import PartnerAddTag from "../components/PartnerAddTag.vue";
import PodcastersList from "../components/PodcastersList.vue";
import AddPodcaster from "../components/AddPodcaster.vue";
import PodcasterDashboard from "../components/PodcasterDashboard.vue";
import PodcastShowsList from "../components/PodcastShowsList.vue";
import AddPodcastShow from "../components/AddPodcastShow.vue";
import PodcasterEpisodesList from "../components/PodcasterEpisodesList.vue";
import PodcastAdminDashboard from "../components/PodcastAdminDashboard.vue";
import PodcastAdminShowsList from "../components/PodcastAdminShowsList.vue";
import AddPodcastAdminShow from "../components/AddPodcastAdminShow.vue";
import PodcastAdminEpisodesList from "../components/PodcastAdminEpisodesList.vue";
import PodcastAdminCampaignsList from "../components/PodcastAdminCampaignsList.vue";
import AddPodcastAdminCampaign from "../components/AddPodcastAdminCampaign.vue";
import PodcasterSignup from "../components/podcaster-dashboard/PodcasterSignup.vue";
import PodcastAdminAlertRoom from "../components/PodcastAdminAlertRoom.vue";
import AdminPublisherOnboardingLinks from "@/components/AdminPublisherOnboardingLinks.vue";
import PublisherOnboard from "@/components/PublisherOnboard.vue";
import PodcasterOverview from "@/components/PodcasterOverview.vue";
import CustomReports from "@/components/CustomReports.vue";
import DemandAdstxtReport from "@/components/DemandAdstxtReport.vue";
import ContentPacksView from "../views/ContentPacksView.vue";
import AddContentPack from "@/components/AddContentPack.vue";
import ArgusSnapshot from "@/components/ArgusSnapshot.vue";
import AdminNewDashboard from "@/components/AdminNewDashboard/AdminNewDashboard.vue";
import ScheduledReports from "@/components/ScheduledReports.vue";
import AddScheduledReport from "@/components/AddScheduledReport.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginAIO,
  },
  {
    path: "/podcaster-signup",
    name: "PodcasterSignup",
    component: PodcasterSignup,
  },
  {
    path: "/publisher-onboard",
    name: "PublisherOnboard",
    component: PublisherOnboard,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/add-seller",
    name: "AddSeller",
    component: AddSeller,
  },
  {
    path: "/add-seller/:sellerId",
    name: "UpdateSeller",
    component: AddSeller,
  },
  {
    path: "/sellers",
    name: "SellersList",
    component: SellersList,
  },
  {
    path: "/groups",
    name: "GroupsList",
    component: SellerGroupList,
  },
  {
    path: "/add-seller-group",
    name: "AddSellerGroup",
    component: AddGroup,
  },
  {
    path: "/add-seller-group/:groupId",
    name: "UpdateSellerGroup",
    component: AddGroup,
  },
  { path: "/tags", name: "PartnerTagsList", component: PartnerTagsList },
  { path: "/add-tag", name: "PartnerAddTag", component: PartnerAddTag },
  {
    path: "/update-tag/:tagId",
    name: "PartnerUpdateTag",
    component: PartnerAddTag,
  },
  {
    path: "/publisher-onboarding-links",
    name: "PublisherOnboardingLinks",
    component: AdminPublisherOnboardingLinks,
  },
  {
    path: "/seller-dashboard/:sellerId",
    name: "PartnerSellerDashboard",
    component: SellerDashboard,
  },
  {
    path: "/group-admin/seller-dashboard/:sellerId",
    name: "GroupAdminSellerDashboard",
    component: SellerDashboard,
  },
  {
    path: "/group-dashboard/:sellerGroupId",
    name: "PartnerGroupDashboard",
    component: GroupAdminDashboard,
  },
  {
    path: "/seller/login",
    name: "SellerLogin",
    component: SellerLogin,
  },
  {
    path: "/seller/dashboard",
    name: "SellerDashboard",
    component: SellerDashboard,
  },
  {
    path: "/group-admin/login",
    name: "GroupAdminLogin",
    component: GroupAdminLogin,
  },
  {
    path: "/group-admin/dashboard",
    name: "GroupAdminDashboard",
    component: GroupAdminDashboard,
  },
  {
    path: "/group-admin/sellers/",
    name: "GroupAdminSellersList",
    component: SellersList,
  },
  {
    path: "/group-admin/add-seller",
    name: "GroupAdminAddSeller",
    component: AddSeller,
  },
  {
    path: "/group-admin/add-seller/:sellerId",
    name: "GroupAdminUpdateSeller",
    component: AddSeller,
  },
  {
    path: "/admin/sellers",
    name: "AdminSellers",
    component: AdminSellers,
  },
  {
    path: "/admin/sellers/edit",
    name: "AdminSellerEdit",
    component: AdminSellerEdit,
    props: (route) => ({
      sellerid: route.query.sellerid,
      partnerid: route.query.partnerid,
      tagbl: route.query.tags_bl,
      tagwl: route.query.tags_wl,
    }),
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin/dashboard-new",
    name: "AdminNewDashboard",
    component: AdminNewDashboard,
  },
  { path: "/admin/tags", name: "TagsList", component: TagsList },
  { path: "/admin/add-tag", name: "AddTag", component: AddTag },
  { path: "/admin/update-tag/:tagId", name: "UpdateTag", component: AddTag },
  {
    path: "/admin/tag-groups",
    name: "TagGroupsList",
    component: TagGroupsList,
  },
  {
    path: "/admin/partners",
    name: "PartnersList",
    component: PartnersList,
  },
  {
    path: "/admin/add-partner",
    name: "AddPartner",
    component: AddPartner,
  },
  {
    path: "/admin/config-manager",
    name: "ConfigManager",
    component: ConfigManager,
    props: (route) => ({
      sellerid: route.query.sellerid,
      partnerid: route.query.partnerid,
    }),
  },
  {
    path: "/admin/add-partner/:partnerId",
    name: "UpdatePartner",
    component: AddPartner,
  },
  {
    path: "/admin/partner-dashboard/:partnerId",
    name: "AdminPartnerDashboard",
    component: Dashboard,
  },
  {
    path: "/admin/ads-txt",
    name: "AdsTxt",
    component: AdminAdsContent,
  },
  {
    path: "/admin/ads-txt-manager",
    name: "AdsTxtManager",
    component: AdminAdsTxtManager,
  },
  {
    path: "/admin/tags-list-checker",
    name: "TagsListChecker",
    component: TagsListChecker,
  },
  {
    path: "/admin/add-seller-group/:groupId",
    name: "AdminUpdateSellerGroup",
    component: AddGroup,
  },
  {
    path: "/admin/groups",
    name: "AdminGroupsList",
    component: SellerGroupList,
  },
  {
    path: "/admin/add-seller",
    name: "AdminAddSeller",
    component: AddSeller,
  },
  {
    path: "/admin/publisher-onboarding-links",
    name: "AdminPublisherOnboardingLinks",
    component: AdminPublisherOnboardingLinks,
  },
  {
    path: "/admin/add-seller/:sellerId",
    name: "AdminUpdateSeller",
    component: AddSeller,
  },
  {
    path: "/admin/scheduled-reports",
    name: "AdminReportSchedules",
    component: ScheduledReports,
  },
  {
    path: "/admin/add-scheduled-report",
    name: "AdminAddScheduledReport",
    component: AddScheduledReport,
  },
  {
    path: "/playlists/create",
    name: "PlaylistCreate",
    component: PlaylistsCreate,
  },
  {
    path: "/playlists",
    name: "PlaylistsList",
    component: PlaylistsList,
  },
  {
    path: "/developers",
    name: "DeveloperAPI",
    component: DeveloperApi,
  },
  {
    path: "/developers/doc",
    name: "DeveloperAPIDoc",
    component: DeveloperApiDoc,
  },
  {
    path: "/group-admin/developers",
    name: "GroupAdminDeveloperAPI",
    component: DeveloperApi,
  },
  {
    path: "/group-admin/developers/doc",
    name: "GroupAdminDeveloperAPIDoc",
    component: DeveloperApiDoc,
  },
  {
    path: "/podcaster/dashboard",
    name: "PodcasterDashboard",
    component: PodcasterDashboard,
  },
  {
    path: "/podcaster/shows",
    name: "PodcastShowsList",
    component: PodcastShowsList,
  },

  {
    path: "/podcaster/episodes",
    name: "PodcasterEpisodesList",
    component: PodcasterEpisodesList,
  },
  {
    path: "/podcast-admin/dashboard",
    name: "PodcastAdminDashboard",
    component: PodcastAdminDashboard,
  },
  {
    path: "/podcast-admin/shows",
    name: "PodcastAdminShowsList",
    component: PodcastAdminShowsList,
    props: true,
  },
  {
    path: "/podcast-admin/add-show",
    name: "AddPodcastAdminShow",
    component: AddPodcastAdminShow,
  },
  {
    path: "/podcast-admin/add-show/:showId",
    name: "UpdatePodcastAdminShow",
    component: AddPodcastAdminShow,
  },
  {
    path: "/podcaster/add-show",
    name: "AddPodcastShow",
    component: AddPodcastShow,
  },
  {
    path: "/podcaster/add-show/:showId",
    name: "UpdatePodcastShow",
    component: AddPodcastShow,
  },

  {
    path: "/podcast-admin/episodes",
    name: "PodcastAdminEpisodesList",
    component: PodcastAdminEpisodesList,
  },
  {
    path: "/podcast-admin/podcasters",
    name: "PodcastersList",
    component: PodcastersList,
  },
  {
    path: "/podcast-admin/add-podcaster",
    name: "AddPodcaster",
    component: AddPodcaster,
  },
  {
    path: "/podcast-admin/add-podcaster/:podcasterId",
    name: "UpdatePodcaster",
    component: AddPodcaster,
  },
  {
    path: "/podcast-admin/podcaster-overview/:podcasterId",
    name: "PodcasterOverview",
    component: PodcasterOverview,
  },
  {
    path: "/podcast-admin/campaigns",
    name: "PodcastAdminCampaignsList",
    component: PodcastAdminCampaignsList,
  },
  {
    path: "/podcast-admin/add-campaign",
    name: "AddPodcastAdminCampaign",
    component: AddPodcastAdminCampaign,
  },
  {
    path: "/podcast-admin/add-campaign/:campaignId",
    name: "UpdatePodcastAdminCampaign",
    component: AddPodcastAdminCampaign,
  },
  {
    path: "/podcast-admin/alert-room",
    name: "PodcastAdminAlertRoom",
    component: PodcastAdminAlertRoom,
  },
  {
    path: "/admin/custom-reports",
    name: "CustomReports",
    component: CustomReports,
  },
  {
    path: "/admin/demand-adstxt-report",
    name: "DemandAdstxtReport",
    component: DemandAdstxtReport,
  },
  {
    path: "/admin/content-packs",
    name: "ContentPacks",
    component: ContentPacksView,
  },
  {
    path: "/admin/content-packs/add",
    name: "AddContentPack",
    component: AddContentPack,
  },
  {
    path: "/admin/content-packs/edit/:id",
    name: "EditContentPack",
    component: AddContentPack,
  },
  {
    path: "/admin/argus-snapshot",
    name: "ArgusSnapshot",
    component: ArgusSnapshot,
  },
  {
    path: "/podcast-admin/podcast-generation",
    name: "PodcastGenerationList",
    component: () => import("@/components/PodcastGenerationList.vue"),
  },
  {
    path: "/podcast-admin/podcast-generation/new",
    name: "AddPodcastGeneration",
    component: () => import("@/components/PodcastGenerationAdd.vue"),
  },
  {
    path: "/podcast-admin/podcast-generation/:taskId/edit",
    name: "EditPodcastGeneration",
    component: () => import("@/components/PodcastGenerationAdd.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

import jwt_decode from "jwt-decode";

router.beforeEach((to, from, next) => {
  try {
    const seller = JSON.parse(localStorage.getItem("seller"));
    store.commit("setSeller", seller);

    const partner = JSON.parse(localStorage.getItem("partner"));
    store.commit("setPartner", partner);

    const groupAdmin = JSON.parse(localStorage.getItem("groupAdmin"));
    store.commit("setGroupAdmin", groupAdmin);

    const admin = JSON.parse(localStorage.getItem("admin"));
    store.commit("setAdmin", admin);

    const podcaster = JSON.parse(localStorage.getItem("podcaster"));
    store.commit("setPodcaster", podcaster);

    const podcastAdmin = JSON.parse(localStorage.getItem("podcastAdmin"));
    store.commit("setPodcastAdmin", podcastAdmin);
  } catch (error) {
    console.log(error);
  }

  if (to.fullPath.includes("/seller/")) {
    sellerLoginValidation(to, from, next);
  } else if (to.fullPath.includes("/admin/")) {
    adminLoginValidation(to, from, next);
  } else if (to.fullPath.includes("/group-admin/")) {
    groupAdminLoginValidation(to, from, next);
  } else if (to.fullPath.includes("/podcaster/")) {
    podcasterLoginValidation(to, from, next);
  } else if (to.fullPath.includes("/podcast-admin/")) {
    podcastAdminLoginValidation(to, from, next);
  } else {
    partnerLoginValidation(to, from, next);
  }
});

function adminLoginValidation(to, from, next) {
  const token = localStorage.getItem("adminToken");

  if (token) {
    try {
      const decoded = jwt_decode(token);

      if (decoded.exp * 1000 > Date.now()) {
        store.commit("setAdminLoggedIn", true);
      } else {
        store.commit("setAdminLoggedIn", false);
        localStorage.removeItem("adminToken");
      }
    } catch (error) {
      store.commit("setAdminLoggedIn", false);
      localStorage.removeItem("adminToken");
    }
  }

  if (!store.state.isAdminLoggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
}

function partnerLoginValidation(to, from, next) {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const decoded = jwt_decode(token);

      if (decoded.exp * 1000 > Date.now()) {
        store.commit("setLoggedIn", true);
        store.commit("setPartnerId", decoded.partner_id);
      } else {
        store.commit("setLoggedIn", false);
        localStorage.removeItem("token");
      }
    } catch (error) {
      store.commit("setLoggedIn", false);
      localStorage.removeItem("token");
    }
  }

  console.log(to.name);
  if (
    !["Login", "PodcasterSignup", "PublisherOnboard"].includes(to.name) &&
    !store.state.isLoggedIn
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
}

function groupAdminLoginValidation(to, from, next) {
  const token = localStorage.getItem("groupAdminToken");

  if (token) {
    try {
      const decoded = jwt_decode(token);

      if (decoded.exp * 1000 > Date.now()) {
        store.commit("setGroupAdminLoggedIn", true);
      } else {
        store.commit("setGroupAdminLoggedIn", false);
        localStorage.removeItem("groupAdminToken");
      }
    } catch (error) {
      store.commit("setGroupAdminLoggedIn", false);
      localStorage.removeItem("groupAdminToken");
    }
  }

  if (!store.state.isGroupAdminLoggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
}

function podcasterLoginValidation(to, from, next) {
  const token = localStorage.getItem("podcasterToken");

  if (token) {
    try {
      const decoded = jwt_decode(token);

      if (decoded.exp * 1000 > Date.now()) {
        store.commit("setPodcasterLoggedIn", true);
      } else {
        store.commit("setPodcasterLoggedIn", false);
        localStorage.removeItem("podcasterToken");
      }
    } catch (error) {
      store.commit("setPodcasterLoggedIn", false);
      localStorage.removeItem("podcasterToken");
    }
  }

  if (!store.state.isPodcasterLoggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
}

function podcastAdminLoginValidation(to, from, next) {
  const token = localStorage.getItem("podcastAdminToken");

  if (token) {
    try {
      const decoded = jwt_decode(token);

      if (decoded.exp * 1000 > Date.now()) {
        store.commit("setPodcastAdminLoggedIn", true);
      } else {
        store.commit("setPodcastAdminLoggedIn", false);
        localStorage.removeItem("podcastAdminToken");
      }
    } catch (error) {
      store.commit("setPodcastAdminLoggedIn", false);
      localStorage.removeItem("podcastAdminToken");
    }
  }

  if (!store.state.isPodcastAdminLoggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
}

function sellerLoginValidation(to, from, next) {
  const token = localStorage.getItem("sellerToken");

  if (token) {
    try {
      const decoded = jwt_decode(token);

      if (decoded.exp * 1000 > Date.now()) {
        store.commit("setSellerLoggedIn", true);
        store.commit("setSellerId", decoded.seller_id);
        store.commit("setSellerPartnerId", decoded.partner_id);
      } else {
        store.commit("setSellerLoggedIn", false);
        localStorage.removeItem("sellerToken");
      }
    } catch (error) {
      store.commit("setSellerLoggedIn", false);
      localStorage.removeItem("sellerToken");
    }
  }

  if (!store.state.isSellerLoggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
}

export default router;
