<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Schedule Report</h2>
    <form @submit.prevent="handleSubmit">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Report Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="report_name">Schedule Name</label>
              <InputText
                id="report_name"
                v-model="formData.report_name"
                :class="{
                  'p-invalid': submitted && !formData.report_name?.length,
                }"
              />
              <small
                v-if="submitted && !formData.report_name?.length"
                class="p-error"
              >
                Please provide a name for the schedule
              </small>
            </div>
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="saved_reports">Select Report</label>
              <Dropdown
                id="saved_reports"
                v-model="formData.report_data_id"
                :options="savedReports"
                option-label="name"
                option-value="id"
                :filter="true"
                filterBy="name"
                placeholder="Select a report"
                :class="{ 'p-invalid': submitted && !formData.report_data_id }"
                @change="handleReportSelect"
              >
              </Dropdown>
              <small
                v-if="submitted && !formData.report_data_id"
                class="p-error"
              >
                Please select a report for schedule
              </small>
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Select Recipients</div>
        </template>
        <template #content>
          <div class="grid">
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="select_admins">Admins</label>
              <MultiSelect
                input-id="select_admins"
                v-model="formData.report_recipients.admins"
                :options="admins"
                option-label="email"
                option-value="id"
                display="chip"
                placeholder="Select Admins"
              />
            </div>
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="select_partners">Partners</label>
              <MultiSelect
                input-id="select_partners"
                v-model="formData.report_recipients.partners"
                :options="filteredRecipients.partners"
                option-label="name"
                option-value="id"
                display="chip"
                placeholder="Select Partners"
              />
            </div>
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="select_groups">Groups</label>
              <MultiSelect
                input-id="select_groups"
                v-model="formData.report_recipients.partner_seller_groups"
                :options="filteredRecipients.partner_seller_groups"
                option-label="name"
                option-value="id"
                display="chip"
                placeholder="Select Groups"
              />
            </div>
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="select_sellers">Sellers</label>
              <MultiSelect
                input-id="select_sellers"
                v-model="formData.report_recipients.partner_sellers"
                :options="filteredRecipients.partner_sellers"
                option-label="name"
                option-value="id"
                display="chip"
                placeholder="Select Sellers"
              />
            </div>
            <small
              v-if="
                submitted &&
                Object.values(formData.report_recipients).every(
                  (arr) => Array.isArray(arr) && arr.length === 0
                )
              "
              class="p-error"
            >
              Please select at least one recipient
            </small>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Schedule</div>
        </template>
        <template #content>
          <div class="grid">
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="choose_schedule">Choose Schedule</label>
              <Dropdown
                id="choose_schedule"
                v-model="formData.report_schedule"
                :options="scheduleOptions"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div></div></template
      ></Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import { onMounted, ref } from "vue";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { useStore } from "vuex";
import Button from "primevue/button";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";

const store = useStore();
const router = useRouter();
const toast = useToast();

const token = localStorage.getItem("adminToken");

const formData = ref({
  report_name: "",
  report_data_id: null,
  report_recipients: {
    admins: [],
    partners: [],
    partner_seller_groups: [],
    partner_sellers: [],
  },
  report_schedule: "0 10 * * *",
});

const scheduleOptions = [
  { label: "Daily", value: "0 10 * * *" },
  { label: "Weekly", value: "0 10 * * 1" },
  { label: "Monthly", value: "0 10 1 * *" },
];

const savedReports = ref([]);

const admins = ref([]);
const partners = ref([]);
const groups = ref([]);
const sellers = ref([]);

const filteredRecipients = ref({
  partners: [],
  partner_seller_groups: [],
  partner_sellers: [],
});

const submitted = ref(false);

const handleReportSelect = (e) => {
  formData.value.report_recipients = {
    admins: [],
    partners: [],
    partner_seller_groups: [],
    partner_sellers: [],
  };
  if (!e.value) {
    filteredRecipients = {
      partners: [],
      partner_seller_groups: [],
      partner_sellers: [],
    };
    return;
  }
  const selectedReport = savedReports.value.find(
    (report) => report.id === e.value
  );
  const reportConfig = JSON.parse(selectedReport.config);

  filteredRecipients.value = {
    partners: partners.value.filter((partner) =>
      reportConfig.selectedPartners.includes(partner.id)
    ),
    partner_seller_groups: groups.value.filter((group) =>
      reportConfig.selectedGroups.includes(group.id)
    ),
    partner_sellers: sellers.value.filter((seller) =>
      reportConfig.selectedSellers.includes(seller.id)
    ),
  };
};

const fetchSavedReports = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(
      `${API_BASE_URL}/custom-reports/saved-reports`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    savedReports.value = response.data.reports;
  } catch (error) {
    console.error("Error fetching saved reports:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchMemberList = async () => {
  try {
    store.commit("setLoading", true);
    const { data } = await axios.get(API_BASE_URL + "/member-list/all", {
      headers: { Authorization: `Bearer ${token}` },
      params: { include_admins: "yes" },
    });
    if (data.success) {
      admins.value = data.admins;
      partners.value = data.partners;
      groups.value = data.groups;
      sellers.value = data.partners;
    }
  } catch (error) {
    console.error("Error fetching member list:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const handleSubmit = async () => {
  try {
    submitted.value = true;

    if (
      !formData.value.report_name?.length ||
      !formData.value.report_data_id ||
      !Object.values(formData.value.report_recipients).some(
        (arr) => Array.isArray(arr) && arr.length > 0
      )
    ) {
      return;
    }

    store.commit("setLoading", true);

    const response = await axios.post(
      `${API_BASE_URL}/scheduled-reports`,
      formData.value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Scheduled report created successfully",
        life: 4000,
      });
      console.log("Scheduled report created successfully");
      router.push({ name: "AdminReportSchedules" });
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to create scheduled report",
      life: 4000,
    });
    console.error("Error creating scheduled report:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

onMounted(() => {
  fetchSavedReports();
  fetchMemberList();
});
</script>
