<template>
  <Dialog
    :visible="visible"
    :modal="true"
    :closable="false"
    :header="`Generate Tag for ${selectedSeller.name}`"
    :style="{ width: '45rem', maxWidth: '92vw' }"
  >
    <div class="grid">
      <div class="col-12">
        <div class="mb-1">Embed Type</div>
        <SelectButton
          v-model="embedType"
          :options="embedTypeOptions"
          option-label="label"
          option-value="value"
        ></SelectButton>
      </div>
      <div class="col-12 sm:col-6">
        <div class="mb-1">Player Layout</div>
        <Dropdown
          v-model="playerOptions.layout"
          :options="layoutOptions"
          optionLabel="label"
          option-value="value"
          placeholder="Select a Layout"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6">
        <div class="mb-1">Player Width</div>
        <InputNumber
          inputId="integeronly"
          :min="300"
          :max="1200"
          placeholder="Player Width"
          v-model="playerOptions.width"
          class="w-full"
        />
      </div>
    </div>
    <div class="grid">
      <div v-if="embedType === 'hard_code'" class="col-12 sm:col-6">
        <div>
          <div class="mr-2">Hide Slogan</div>
          <div>
            <InputSwitch v-model="playerOptions.hide_slogan" />
          </div>
        </div>
      </div>

      <div class="col-12 sm:col-6">
        <div>
          <div class="mr-2">Hide FAB</div>
          <InputSwitch v-model="playerOptions.hide_fab" />
        </div>
      </div>
      <div class="col-12 sm:col-6">
        <div>
          <div class="mr-2">FAB on left side</div>
          <InputSwitch v-model="playerOptions.fab_left" />
        </div>
      </div>
      <div class="col-12 sm:col-6">
        <div>
          <div class="mr-2">Open in new tab when play</div>
          <InputSwitch v-model="playerOptions.go_external_on_play" />
        </div>
      </div>
      <div class="col-12 sm:col-6">
        <div>
          <div class="mr-2">Disable playlist shuffling</div>
          <InputSwitch v-model="playerOptions.disable_shuffling" />
        </div>
      </div>
      <div class="col-12 sm:col-6">
        <div>
          <div class="mr-2">Thumbnail Color Scheme</div>
          <div class="flex gap-2 align-items-center">
            <ColorPicker v-model="playerOptions.color_scheme" />
            <!-- Clear Button -->
            <Tag
              style="cursor: pointer"
              :style="
                playerOptions.color_scheme?.length
                  ? 'visibility: visible'
                  : 'visibility: hidden'
              "
              icon="pi pi-times"
              severity="secondary"
              value="Clear"
              rounded
              @click="playerOptions.color_scheme = ''"
            ></Tag>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="mb-1">
        Copy & paste this code to where you want to embed the player.
      </div>
      <div
        class="bg-primary-reverse overflow-x-hidden border-1 border-round-md p-3 mb-2 relative"
      >
        <Button
          @click="codeToClipboard('componentcode')"
          icon="pi pi-copy"
          text
          aria-label="Copy"
          v-tooltip.bottom="'Copy'"
          class="absolute right-0 top-0 embed-code-copy-btn"
        />
        <code ref="componentcodeElement">{{ componentCode }}</code>
      </div>
      <div v-if="embedType === 'gam'" class="mb-1">Instruction</div>
      <div v-if="embedType === 'gam'">
        <ol class="mt-0">
          <li
            v-for="gamInstruction in gamInstructions"
            :key="gamInstruction.id"
          >
            {{ gamInstruction }}
          </li>
        </ol>
      </div>
    </div>
    <div class="mt-3 mb-1 font-medium">ads.txt contents</div>
    <div class="grid">
      <div class="col-12 sm:col-6">
        <div class="mb-1">Priority</div>
        <MultiSelect
          v-model="adsTxtOptions.priority"
          :options="priorityOptions"
          optionLabel="label"
          option-value="value"
          placeholder="Select Priorities"
          class="w-full"
        />
      </div>
      <div class="col-12 sm:col-6">
        <div class="mb-1">Type</div>
        <MultiSelect
          v-model="adsTxtOptions.content_type"
          :options="contentTypeOptions"
          optionLabel="label"
          option-value="value"
          placeholder="Select Types"
          class="w-full"
        />
      </div>
    </div>
    <div
      class="surface-50 surface-border text-color-secondary border-1 overflow-x-hidden border-round-md p-3 mb-2 relative"
    >
      <Button
        @click="codeToClipboard('adstxt')"
        icon="pi pi-copy"
        text
        aria-label="Copy"
        security="secondary"
        v-tooltip.bottom="'Copy'"
        class="absolute right-0 top-0 embed-code-copy-btn"
      />

      <pre ref="adstxtElement" class="my-0">{{ adsTxtCode }}</pre>
    </div>
    <div class="my-1">
      <p class="my-0">This content should be added to:</p>
      <p class="text-blue-500 my-0">/ads.txt</p>
    </div>
    <template #footer>
      <Button
        v-if="partner.domain && partner.id"
        label="Save as file"
        @click="saveEmbedAsFile"
      />
      <Button label="Close" class="mt-3" outlined @click="closeDialog" />
    </template>
  </Dialog>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";
import MultiSelect from "primevue/multiselect";
import { adsTxtFixed } from "@/consts";
import { downloadTextFile, generateEmbedGuide } from "@/helpers";
import SelectButton from "primevue/selectbutton";
import ColorPicker from "primevue/colorpicker";
import Tag from "primevue/tag";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  selectedSeller: {
    type: Object,
    default: () => ({}),
  },
  adsTxtContent: {
    type: Array,
    default: () => [],
  },
  partner: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["close-dialog"]);

const playerOptions = ref({
  layout: props.partner?.default_widget_layout || "0",
  width: 0,
  hide_slogan: false,
  hide_fab: false,
  fab_left: false,
  disable_shuffling: false,
  go_external_on_play: false,
  color_scheme: "",
});

const componentcodeElement = ref(null);
const componentsrcElement = ref(null);
const adstxtElement = ref(null);

const adsTxtOptions = ref({
  priority: [1, 2, 3],
  content_type: [
    "audio",
    "display",
    "video",
    "app_audio",
    "app_display",
    "app_video",
  ],
});

const layoutOptions = [
  { label: "Low Visible", value: "0" },
  { label: "High Visible", value: "1" },
];

const yesNoOptions = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];

const priorityOptions = [
  { label: "Low", value: 3 },
  { label: "High", value: 2 },
  { label: "Critical", value: 1 },
];

const contentTypeOptions = [
  { label: "Audio", value: "audio" },
  { label: "Display", value: "display" },
  { label: "Video", value: "video" },
];

const embedTypeOptions = [
  { label: "Hard Code", value: "hard_code" },
  { label: "App", value: "app" },
  { label: "GAM", value: "gam" },
];

const gamInstructions = [
  'You must ensure it\'s not in a safe frame and allow "child-directed requests."',
  "*Iframe is also not recommended since it will block some demand partners from bidding.",
  "As for the domain, you can either keep it hard coded (for a single domain) or use the GAM URL/SITE macro",
  "The wrapper should also have a set width and height as well as the widget",
  "We do not support AMP pages; the code type should be set to Standard.",
];

const gamGuide = gamInstructions
  .map((element, index) => `${index + 1}. ${element}`)
  .join("\n");

const embedType = ref("hard_code");

const componentSrc = computed(() => {
  const domain = "wgt.podcastswaves.com";
  return `<script src="https://${domain}/jamx.player.js"><\/script>`;
});

const componentCode = computed(() => {
  const tagName = "jamx";
  const versionAttr = "layout";
  const versionValue = playerOptions.value.layout;
  const deviceIdMacro = ` deviceid="[REPLACE WITH DEVICE ID MACRO]"`;
  const bundleIdMacro = ` bundleid="[REPLACE WITH BUNDLE ID MACRO]"`;
  const gamMacro = `[REPLACE WITH GAM DOMAIN MACRO]`;
  return `<${tagName}-player seller="${props.partner.id}" subseller="${
    props.selectedSeller.id
  }" domain="${
    embedType.value === "gam" ? gamMacro : props.selectedSeller.domain
  }" ${versionAttr}="${versionValue}" width="${playerOptions.value.width}"${
    embedType.value === "app" ? `${deviceIdMacro}${bundleIdMacro}` : ""
  }${playerOptions.value.hide_slogan ? " hide_slogan" : ""}${
    playerOptions.value.hide_fab ? " hide_fab" : ""
  }${playerOptions.value.fab_left ? " fab_left" : ""}${
    playerOptions.value.disable_shuffling ? " disable_shuffling" : ""
  }${playerOptions.value.go_external_on_play ? " go_external_on_play" : ""}${
    playerOptions.value.color_scheme?.length
      ? ` color_scheme="#${playerOptions.value.color_scheme}"`
      : ""
  }><\/${tagName}-player>${componentSrc.value}`;
});

const adsTxtCode = computed(() => {
  return `${adsTxtFixed(
    props.selectedSeller.group_id,
    props.selectedSeller.group_display_id
  )}\n${props.adsTxtContent
    .filter(
      (item) =>
        adsTxtOptions.value.content_type.includes(item.type) &&
        adsTxtOptions.value.priority.includes(item.priority)
    )
    .map((row) => row.row_string)
    .join("\n")}`;
});

watch(
  () => props.visible,
  (newValue) => {
    if (newValue) {
      console.log("The dialog is now visible.");
    } else {
      console.log("The dialog is now hidden.");
      adsTxtOptions.value.content_type = ["audio", "display", "video"];
      adsTxtOptions.value.priority = [1, 2, 3];
    }
  }
);

watch(embedType, (newEmbedType, oldEmbedType) => {
  if (newEmbedType !== "hard_code") {
    playerOptions.value.hide_slogan = true;
    if (newEmbedType === "app") playerOptions.value.hide_fab = true;
  }
});

function closeDialog() {
  emit("close-dialog");
}

function codeToClipboard(refName) {
  const elementsArr = {
    componentcode: componentcodeElement,
    componentsrc: componentsrcElement,
    adstxt: adstxtElement,
  };
  const clipboardData = elementsArr[refName].value.innerText;
  navigator.clipboard.writeText(clipboardData);
}

function saveEmbedAsFile() {
  const embedData = generateEmbedGuide(
    componentCode.value,
    embedType.value === "gam" ? gamGuide : componentSrc.value,
    adsTxtCode.value,
    embedType.value === "gam"
  );
  downloadTextFile(embedData, "how_to_embed.txt");
}
</script>

<style>
.embed-mode-select > * {
  width: 50%;
}

.embed-code-copy-btn:focus {
  box-shadow: none !important;
}
</style>
