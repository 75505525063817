<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">{{ title }}</h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column md:flex-row justify-content-between gap-3 relative"
        >
          <div
            v-if="selectedSellers.length"
            class="absolute top-100 left-0 pl-3 pt-2 text-sm"
          >
            {{ selectedSellers.length }} Sellers Selected
          </div>
          <div class="flex flex-column sm:flex-row gap-3 sm:gap-2">
            <Button
              size="small"
              v-if="
                !isGroupAdmin ||
                (isGroupAdmin && store.getters.groupAdmin.can_add_sellers)
              "
              class="justify-content-center"
              @click="goToCreateNewSeller"
              label="Add New"
              icon="pi pi-plus"
            />
            <Button
              size="small"
              :outlined="!selectedSellers.length"
              severity="danger"
              :disabled="!selectedSellers.length"
              icon="pi pi-trash"
              class="justify-content-center"
              label="Delete"
              @click="ConfirmSellersDelete"
            />
            <Button
              size="small"
              :outlined="!selectedSellers.length"
              severity="help"
              :disabled="!selectedSellers.length"
              icon="pi pi-file-export"
              class="justify-content-center"
              label="Export Embed Code"
              @click="exportEmbedData"
            />
            <Button
              size="small"
              :outlined="!selectedSellers.length"
              severity="info"
              :disabled="!selectedSellers.length"
              icon="pi pi-download"
              class="justify-content-center"
              label="Export CSV"
              @click="exportSellersData"
            />
          </div>
          <InputText
            class="w-full sm:w-20rem"
            type="text"
            v-model="sellersTablesFilters.global.value"
            placeholder="Global Search"
          />
        </div>
      </template>
      <template #content>
        <DataTable
          :value="sellers"
          class="p-datatable-sm"
          dataKey="id"
          v-model:selection="selectedSellers"
          :paginator="true"
          :rows="10"
          :sort-field="'id'"
          :sort-order="-1"
          :filters="sellersTablesFilters"
          :loading="sellersTableLoading > 0"
        >
          <Column
            selectionMode="multiple"
            style="width: 3rem"
            :exportable="false"
          ></Column>
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column field="domain" header="Domain" :sortable="true"></Column>
          <Column
            v-if="!isGroupAdmin"
            field="group_name"
            header="Group"
            :sortable="true"
          ></Column>

          <Column
            field="content"
            class="max-w-20rem"
            header="Content"
            :sortable="true"
          >
            <template #body="slotProps">
              <div
                v-if="
                  slotProps.data.content_type === 'categories' &&
                  slotProps.data.content.length
                "
                class="flex gap-1 flex-wrap"
              >
                <Chip
                  v-for="(category, index) in slotProps.data.content?.split(
                    ','
                  )"
                  :key="index"
                  :label="category.trim()"
                  class="white-space-nowrap text-xs surface-100 border-1 border-primary-300"
                />
              </div>
              <div v-else class="line-clamp-1 white-space-nowrap">
                <span>{{
                  `${
                    slotProps.data.content_type === "rss_feed"
                      ? "rss: "
                      : slotProps.data.content_type === "playlist"
                      ? "playlist: "
                      : ""
                  }${slotProps.data.content}`
                }}</span>
              </div>
            </template>
          </Column>
          <Column field="status" header="Status" :sortable="true">
            <template #body="slotProps">
              <Badge
                :severity="
                  slotProps.data.status == 'approved'
                    ? 'success'
                    : slotProps.data.status == 'pending'
                    ? 'warning'
                    : 'danger'
                "
                class="capitalize border-round-3xl"
                :value="slotProps.data.status"
              />
            </template>
          </Column>
          <Column field="adstxt_verified" header="ads.txt">
            <template #body="slotProps">
              <AdstxtValResultTag :data="slotProps.data" />
            </template>
          </Column>
          <Column field="created_at" header="Created At" :sortable="true">
            <template v-slot:body="slotProps">
              {{ formatDate(slotProps.data.created_at) }}
            </template>
          </Column>

          <Column>
            <template #body="slotProps">
              <div class="flex gap-2 justify-content-between">
                <Button
                  v-if="
                    !isGroupAdmin ||
                    (isGroupAdmin && store.getters.groupAdmin.can_add_sellers)
                  "
                  v-tooltip.left="'Edit'"
                  icon="pi pi-pencil"
                  outlined
                  rounded
                  @click="goToUpdateSubSeller(slotProps.data.id)"
                />
                <Button
                  icon="pi pi-chart-line"
                  v-tooltip.left="'Dashboard'"
                  outlined
                  rounded
                  severity="help"
                  @click="goToSellerDashboard(slotProps.data.id)"
                />
                <Button
                  v-if="slotProps.data.status == 'approved'"
                  icon="pi pi-code"
                  v-tooltip.left="'Embed Widget'"
                  outlined
                  rounded
                  severity="success"
                  @click="openGenerateTagDialog(slotProps.data)"
                />
                <Button
                  v-if="
                    !isGroupAdmin ||
                    (isGroupAdmin && store.getters.groupAdmin.can_add_sellers)
                  "
                  v-tooltip.left="'Duplicate'"
                  icon="pi pi-clone"
                  outlined
                  rounded
                  @click="ConfirmSellersClone(slotProps.data)"
                />
                <Button
                  icon="pi pi-trash"
                  v-tooltip.left="'Delete'"
                  outlined
                  rounded
                  severity="danger"
                  @click="ConfirmSellersDelete(slotProps.data)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>

  <GenerateTagDialog
    v-model:visible="showGenerateTagDialog"
    :selectedSeller="this.selectedSeller"
    :partner="partner"
    @close-dialog="showGenerateTagDialog = false"
    @hide="showGenerateTagDialog = false"
    :adsTxtContent="adsTxtContent"
  />
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import { FilterMatchMode } from "primevue/api";
import Card from "primevue/card";
import Button from "primevue/button";

import GenerateTagDialog from "./GenerateTagDialog.vue";
import InputText from "primevue/inputtext";
import Chip from "primevue/chip";
import Badge from "primevue/badge";
import { exportCSV, formatDate, generateEmbedExport } from "../helpers";
import { downloadTextFile } from "../helpers";
import AdstxtValResultTag from "./AdstxtValResult/AdstxtValResultTag.vue";

export default {
  components: {
    GenerateTagDialog,
    InputText,
    Chip,
    Card,
    Button,
    Badge,
    AdstxtValResultTag,
  },
  setup() {
    const sellers = ref([]);
    const selectedSellers = ref([]);
    const sellersTableLoading = ref(0);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const confirm = useConfirm();
    const groupAdmin = JSON.parse(localStorage.getItem("groupAdmin"));
    const partner = ref(
      JSON.parse(localStorage.getItem("partner")) || {
        ...JSON.parse(localStorage.getItem("groupAdmin")),
        id: groupAdmin?.partner_id,
        domain: groupAdmin?.partner_domain,
      }
    );

    const sellersTablesFilters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const path = router.currentRoute.value.path;
    let localStorageKey = "token";

    if (path.includes("/group-admin/")) {
      localStorageKey = "groupAdminToken";
    }

    const token = localStorage.getItem(localStorageKey);

    const playlistsOptions = ref([]);
    const showGenerateTagDialog = ref(false);
    const selectedSeller = ref({ name: "", type: "regular" });
    const generatedEmbedCode = ref("");
    const playerOptions = ref({});
    const adsTxtContent = ref([]);

    const openGenerateTagDialog = (seller) => {
      selectedSeller.value = seller;
      showGenerateTagDialog.value = true;
    };

    const ConfirmSellersDelete = (sellerToDel) => {
      confirm.require({
        message: "Do you want to delete selected seller(s)?",
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          deleteSellers(sellerToDel.id);
        },
      });
    };

    const ConfirmSellersClone = (sellerToClone) => {
      confirm.require({
        message: "Do you want to clone selected seller(s)?",
        header: "Clone Confirmation",
        icon: "pi pi-info-circle",
        accept: () => {
          cloneSeller(sellerToClone.id);
        },
      });
    };

    const exportSellersData = () => {
      const csvData = selectedSellers.value.map((item) => ({
        seller_id: item.id,
        domain: item.domain,
      }));
      exportCSV(csvData);
    };

    const exportEmbedData = () => {
      const textContent = generateEmbedExport(
        partner.value,
        selectedSellers.value
      );
      downloadTextFile(textContent, "ads.txt");
      selectedSellers.value = [];
    };

    const goToCreateNewSeller = () => {
      router.push({
        name: `${isGroupAdmin.value ? "GroupAdmin" : ""}AddSeller`,
      });
    };

    const goToUpdateSubSeller = (sellerId) => {
      router.push({
        name: `${isGroupAdmin.value ? "GroupAdmin" : ""}UpdateSeller`,
        params: { sellerId: sellerId },
      });
    };

    const isURL = (str) => {
      try {
        new URL(str);
      } catch (_) {
        return false;
      }
      return true;
    };

    const isGroupAdmin = computed(() => {
      return route.name == "GroupAdminSellersList";
    });

    const title = isGroupAdmin.value ? "Domains" : "Sellers";

    const goToSellerDashboard = (sellerId) => {
      const dbPath = isGroupAdmin.value
        ? "/group-admin/seller-dashboard/"
        : "/seller-dashboard/";
      router.push({
        path: dbPath + sellerId,
      });
    };

    const parseCategories = (categories) => {
      try {
        return JSON.parse(categories)
          .map((item) => item.split("_").slice(1).join("_"))
          .filter((item) => item.trim() !== "")
          .join(",");
      } catch (error) {
        return "";
      }
    };

    const fetchPlaylists = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/playlists", {
          headers: { Authorization: `Bearer ${token}` },
        });
        playlistsOptions.value = response.data.playlists.filter(
          (item) => item.status == "ready"
        );
        await fetchSellers();
      } catch (error) {
        // Handle errors, e.g., show an error message
        console.error("Error fetching playlists:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchSellers = async () => {
      try {
        sellersTableLoading.value++;
        const response = await axios.get(API_BASE_URL + "/sellers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          response.data = response.data.map((item) => {
            switch (item.content_type) {
              case "categories":
                item.content = parseCategories(item.categories);
                break;
              case "rss_feed":
                item.content = item.rss_feed;
                break;
              case "playlist":
                item.content = playlistsOptions.value.find(
                  (option) => option.uuid === item.playlist_id
                );
                item.content = item.content?.name;
                break;
              case "shows":
                item.content = "show: " + item.show_ids_content;
                break;
              default:
                break;
            }
            return item;
          });
          sellers.value = response.data;
        }
      } catch (error) {
        console.error("Error fetching sellers:", error);
      } finally {
        sellersTableLoading.value--;
      }
    };

    const deleteSellers = async (idToDel) => {
      try {
        sellersTableLoading.value++;

        const sellerIds = idToDel
          ? [idToDel]
          : selectedSellers.value.map((item) => item.id);

        const response = await axios.delete(API_BASE_URL + "/sellers", {
          params: { sellerIds: JSON.stringify(sellerIds) },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          toast.add({
            severity: "info",
            summary: "Confirmed",
            detail: `Seller(s) deleted`,
            life: 4000,
          });
          fetchSellers();
          selectedSellers.value = [];
        }
      } catch (error) {
        console.error("Error fetching sellers:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong...",
          life: 4000,
        });
      } finally {
        sellersTableLoading.value--;
      }
    };

    const cloneSeller = async (sellerId) => {
      try {
        sellersTableLoading.value++;

        const response = await axios.post(
          API_BASE_URL + "/sellers/duplicate",
          { sellerId: sellerId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          toast.add({
            severity: "info",
            summary: "Confirmed",
            detail: `Seller has been duplicated`,
            life: 4000,
          });
          fetchSellers();
        }
      } catch (error) {
        console.error("Error duplicating seller:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong...",
          life: 4000,
        });
      } finally {
        sellersTableLoading.value--;
      }
    };

    const fetchAdsTxt = async () => {
      try {
        const response = await axios.get(API_BASE_URL + "/adstxt-rows", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.result) {
          adsTxtContent.value = response.data.result;
        }
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(fetchPlaylists);
    onMounted(fetchAdsTxt);

    return {
      store,
      sellers,
      partner,
      goToCreateNewSeller,
      goToUpdateSubSeller,
      ConfirmSellersDelete,
      ConfirmSellersClone,
      sellersTableLoading,
      showGenerateTagDialog,
      selectedSeller,
      generatedEmbedCode,
      openGenerateTagDialog,
      goToSellerDashboard,
      playerOptions,
      isURL,
      sellersTablesFilters,
      isGroupAdmin,
      title,
      playlistsOptions,
      formatDate,
      adsTxtContent,
      selectedSellers,
      exportEmbedData,
      exportSellersData,
    };
  },
};
</script>

<style scoped>
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
}
</style>
